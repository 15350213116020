export type ResponseType<Type> = {
  status: number,
  response: Type,
}

export class HttpService {

  request<T>(type: string, endpoint: string, data?: any): Promise<ResponseType<T>> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(type, endpoint);

      xhr.onreadystatechange = (() => {
        if (xhr.readyState !== 4) return;

        if (xhr.status !== 0 && (xhr.status < 200 || xhr.status >= 400)) {
          reject({
            status: xhr.status,
            response: xhr.responseText
          });
          return;
        }

        // NOTE: Probably also an error? Seen when:
        // - Server isn't running/Cors Failure

        if (xhr.status === 0) {
          try {
            const response = xhr.responseText ? JSON.parse(xhr.responseText) : "";
            resolve({
              status: xhr.status,
              response,
            });
          } catch (error) {
            console.error('[ERROR:STATUS:0]: ', xhr.responseText, error);
            reject(error);
          }
          
          return;
        }

        resolve({
          status: xhr.status,
          response: JSON.parse(xhr.responseText)
        });
        return;
      });

      xhr.send(data);

    });
  }

  get<T>(endpoint: string) {
    return this.request<T>('GET', endpoint);
  }

  post<T>(endpoint: string, data: any): Promise<any> {
    return this.request<T>('POST', endpoint, data);
  }

  patch<T>(endpoint: string, data: any): Promise<any> {
    return this.request<T>('PATCH', endpoint, data);
  }

  put<T>(endpoint: string, data: any): Promise<any> {
    return this.request<T>('PUT', endpoint, data);
  }

  delete<T>(endpoint: string): Promise<any> {
    return this.request<T>('DELETE', endpoint);
  }
}

export default new HttpService();
