import Config from "./app/_services/config.service";

export function Plausible() {
  const plausibleDomain = Config.get('PLAUSIBLE_DOMAIN');
  if (!plausibleDomain) {
    return null;
  }

  const script = document.createElement('script');
  script.defer = true;
  script.dataset.domain = plausibleDomain;
  script.src = "https://plausible.io/js/script.js";
  document.head.appendChild(script)
}