import React, { useState, useEffect } from 'react';

type ErrorState = {
  message: string
}
export function usePromiseLoader<DataType>(promiseFn: () => Promise<DataType>, dependencyList: React.DependencyList) {
  const [loading, setLoadingState] = useState(false);
  const [data, setDataState] = useState<DataType | undefined>();
  const [error, setErrorState] = useState<ErrorState | undefined>();

  useEffect (() => {
    let ignore = false;
    const fetchUrl = async () => {
      setLoadingState(true);
      try {
        setErrorState(undefined);
        const response = await promiseFn();
        if (!ignore) setDataState(response);
      } catch (error) {
        setErrorState(error)
      }
      setLoadingState(false);
    }
    if (!ignore) {
      fetchUrl();
    }
    return (() => { ignore = true;})
    // promise is not a primitive, so we tell the caller to pass us a dependencyList we can rely on
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)

  return {loading, data, error};
}