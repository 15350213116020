import * as parcer from 'parse-address';

export function formatAddressShort(inputAddress: string) {
  const trailingCounty = / allegheny$/;
  const trailingDashZero = /-0$/;
  const trailingZero = / 0$/;
  const trailingDash = /-$/;
  const comma = /,/;
  const multipleWhitespace = /\s\s+/g;

  const cleanedAddress = inputAddress.toLowerCase().trim()
    .replace(multipleWhitespace, ' ').trim()
    .replace(trailingCounty, '').trim()
    .replace(trailingDashZero, '').trim()
    .replace(trailingZero, '').trim()
    .replace(trailingDash, '').trim()
    .replace(comma, ' ')
    .replace(multipleWhitespace, ' ');

  const parsed = parcer.parseLocation(cleanedAddress);

  let address = '';
    address += `${parsed.number} `;
    if (parsed.prefix) address += `${parsed.prefix} `;
    address += `${parsed.street} `;
    address += `${parsed.type}`;
    if (parsed.suffix) address += ` ${parsed.suffix}`;
    if (parsed.sec_unit_type) address += `, ${parsed.sec_unit_type}`;
    if (parsed.sec_unit_num) address += ` ${parsed.sec_unit_num}`;

  address = address.toLowerCase();
  return address;
}