import { memo } from "react";

import { 
  default as apiService
} from '../../../_services/api.service';
import { usePromiseLoader } from '../../../_services/usePromiseLoader';
import { Corporation } from "../../../_components/corporation/Corporation";
import { formatAddressShort } from "./helpers";

export const CorporationsList = memo(CorporationsListComponent, (prevProps, nextProps) => {
  const prevCorpIds = prevProps.corporationIds.join();
  const nextCorpIds = nextProps.corporationIds.join();
  return prevCorpIds === nextCorpIds;
});

function CorporationsListComponent({corporationIds} : {corporationIds: string[]}) {

  const {loading, error, data} = usePromiseLoader(() => getMultipleCorporationInfos(corporationIds), [corporationIds.join()]);

  const corpArrayByAddress = [];
  if (data?.length) {
    const corpMapByAddress = new Map<string, any>();
    for (let i = 0; i < data.length; i++) {
      const corp = data[i]?.response as any;
      const address = formatAddressShort(corp.details.Address);
      if (!corpMapByAddress.has(address)) {
        corpMapByAddress.set(address, []);
      }
      corpMapByAddress.get(address).push(corp);
    }
    corpMapByAddress.forEach((value, key) => {
      corpArrayByAddress.push([key, value]);
    });
  }

  return (
    <>
      {loading && (<p>Loading...</p>)}
      {error && (<p>Error: {error.message}</p>)}
      {corpArrayByAddress.length && (
        corpArrayByAddress.map(([address, corporations]) => {
          return (
            <div key={address}>
              <h3>{address}</h3>
              <ul>{
                corporations.map((corpInfo) => {
                  return (<Corporation key={corpInfo.id} corpInfo={corpInfo}/>)
                })
              }</ul>
            </div>
          )
        })
      )}
    </>
  );

}

function getMultipleCorporationInfos(corporationIds: string[]) {
  return Promise.all(corporationIds.map((id) => apiService.getCorporationInfo(id)));
}