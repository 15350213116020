import './Sidebar.css';
import { memo } from "react";
import { AddressFeature } from '../../../_services/api.service';
import { CorporationsList } from './CorporationsList';

export type sidebarStates = "open" | "closed";

export type SidebarData = {
  parcels?: string[],
  addresses?: AddressFeature["properties"][]
}

export const Sidebar = memo(SidebarComponent);

function SidebarComponent({ 
  sidebarState, 
  sidebarData, 
  setSidebarState
}: {
  sidebarState: sidebarStates,
  sidebarData: SidebarData,
  setSidebarState: React.Dispatch<React.SetStateAction<sidebarStates>>
}) {

  const isOpen = sidebarState === 'open';
  const toggleClass = isOpen ? 'close' : 'open';

  const toggleSidebar = () => {
    const newState = sidebarState === 'closed' ? 'open' : 'closed';
    setSidebarState(newState);
  }

  let corporationIds = sidebarData?.addresses?.map((address) => address.CorpId) ?? [];
  const hasAddresses = !!(corporationIds.length);

  return (
    <div className={`map-sidebar ${sidebarState}`}>
      <div className="map-sidebar--content">
      {(!hasAddresses) && (
        <p>
          Please select a corporation to view more information about it.
        </p>
      )}
      {hasAddresses && (
        <>
          <h2>Corporations by Address:</h2>
          <CorporationsList corporationIds={corporationIds}></CorporationsList>
        </>
      )}
      </div>
      <button className="map-sidebar--toggle" onClick={toggleSidebar}>
        <span className="map-sidebar--toggle-text">{isOpen ? 'Close sidebar' : 'Open sidebar'}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`icon-open-close icon-${toggleClass} bi bi-chevron-right`} viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
    </div>
  )
}