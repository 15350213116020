import { useState, useRef, useEffect } from 'react';
import './Map.css';
import { Sidebar, sidebarStates, SidebarData } from './Sidebar/Sidebar';
import { MapBox } from './MapBox/MapBox';
import { URLSearchParamsInit, createSearchParams, useSearchParams } from 'react-router-dom'

export type Coords = {lat: number, long: number, zoom: number};
export type SetCoordsAction = React.Dispatch<React.SetStateAction<Coords>>;
export type SelectedCorpIds = number[];

export function Map() {

  const startingLong = -80.0196;
  const startingLat = 40.3931;
  const startingZoom = 18.1;

  useEffect(() => {
    const pageClass = '_page-Map';
    document.body.classList.add(pageClass);
    return () => {
      document.body.classList.remove(pageClass);
    }
  });

  // Note: check performance of using lat, long, zoom in url
  // we really only want to read from the url on the first load, and then write to it after that
  // what we don't want is moving the map to cause the url to change, which causes additional state 
  // changes which causes the map to re-render
  const [searchParams, setSearchParams] = useSearchParams(paramsToString({lat: startingLat, long: startingLong, zoom: startingZoom}));
  

  // this is being called three times when a map changes. figure out why
  console.log("searchParams", searchParams)

  const [sidebarState, setSidebarState] = useState<sidebarStates>("closed");
  const [sidebarData, setSidebarData] = useState<SidebarData>({parcels: undefined, addresses: undefined});
  const map = useRef(null);

  const startingCoords: Coords = {
    long: parseFloat(searchParams.get('long')), 
    lat: parseFloat(searchParams.get('lat')), 
    zoom: parseFloat(searchParams.get('zoom')),
  };

  const startingCorpIds = searchParams.getAll('corpId').map(id => parseInt(id)) || [];

  const [coords, setCoords] = useState(startingCoords);

  const [selectedCorpIds, setSelectedCorpIds] = useState<SelectedCorpIds>(startingCorpIds);

  const {lat, long, zoom} = coords;

  useEffect(() => {
    const params: URLSearchParamsInit = paramsToString({lat, long, zoom, corpIds: selectedCorpIds});
    setSearchParams(params, { replace: true });
  }, [lat, long, zoom, selectedCorpIds, setSearchParams]);

  const coordsProps = {
    long,
    lat,
    zoom,
    setCoords,
  };

  // this needs to be a ref to be used in map.on('click') function due to a Mapbox issue: https://github.com/alex3165/react-mapbox-gl/issues/963\
  const previousLayer = useRef('');  // needed to turn visibility of layers on and off accordingly

  return (
    <>
      <MapBox {...{map, setSidebarState, setSidebarData, previousLayer, coords: coordsProps, selectedCorpIds, setSelectedCorpIds}} />
      <Sidebar {...{sidebarState, setSidebarState, sidebarData}}></Sidebar>
      {/* <AccessibleSearchBox onChangeLatLng={handleLatLng}></AccessibleSearchBox> */}
    </>
  );
}

function paramsToString({lat, long, zoom, corpIds}: Coords & { corpIds?: number[]}) {
  let paramsObj = {
    lat: lat.toString(), 
    long: long.toString(), 
    zoom: zoom.toString(), 
  };
  if (corpIds && corpIds.length) {
    paramsObj['corpId'] = corpIds;
  }
  // const params = new URLSearchParams(paramsObj);
  const params = createSearchParams(paramsObj);
  return `?${params}`;
}