type CorporationInfo = {
  id: number, 
  name: string,
  namesHistory: {
    "Current Name": string
  }[],
  officers?: {
    "Name": string,
    "Title": string,
    "Address": string
  }[],
  details: {
    "Name": string,
    "Status": string,
    "Address": string,
    "Citizenship": string,
    "Entity Type": string,
    "Entity Number": string,
    "Effective Date": string,
    "Entity Creation Date": string
  }
};

export function Corporation (
  {corpInfo} : {corpInfo: CorporationInfo}) {
  const creationDate = corpInfo.details["Entity Creation Date"];
  const creationYear = creationDate.split("/")[2];
  return (<li>
    {corpInfo.name} [{creationYear}]<br/>
    {(corpInfo.namesHistory.length > 1) && (
      <>
        {(corpInfo.namesHistory.length-1) + " other names"}
        <br/>
      </>
    )}
    {corpInfo.officers?.length && (
      corpInfo.officers?.length + " officers"
    )}
  </li>)
}