import { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import ConfigService from '../../../_services/config.service';
import ApiService from '../../../_services/api.service';
import { SidebarData} from '../Sidebar/Sidebar';
import { SetCoordsAction, SelectedCorpIds } from '../Map';
import { sidebarStates } from '../Sidebar/Sidebar';



// NOTE: fix for mapbox-gl transpiling issue: https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = ConfigService.get('MAP_TOKEN');

type SourceName = 
  'all-parcels' |
  'all-addresses' |
  'linked-parcels' |
  'linked-addresses' |
  'selected-addresses' | 
  'selected-parcels';

type ShadingLayerId =
  'all-parcels-shading' |
  'linked-parcels-shading' |
  'selected-parcels-shading';

type LayoutVisibilityProperty = 'visible' | 'none';

type CircleLayerId = 
  'all-address-points' |
  'linked-address-points' |
  'selected-address-points';

type CoordsProps = {
  long: number,
  lat: number,
  zoom: number,
  setCoords: SetCoordsAction,
};

/* 

We should think of layers like photoshop et el does. They are discrete layers that 
can be turned on and off and you can modify any of them without having to change their order.
your control layer should always be on top tho I think? aka, the selector when you highlight things.

Is there a way for layers to be visible but allow clicks to pass through them?

Actually, by default clicks might return all layers that are visible, so we might need to
filter the results down to the currently active layer.

I want to be able to define base layers, load dynamic layers (from a db), 
have highlight layers (and maybe link layers too), and then have a

*/

export function MapBox({
  map,
  setSidebarState,
  setSidebarData, 
  previousLayer,
  coords,
  selectedCorpIds, 
  setSelectedCorpIds
}: {
  map: React.MutableRefObject<any>,
  setSidebarState: React.Dispatch<React.SetStateAction<sidebarStates>>,
  setSidebarData: React.Dispatch<React.SetStateAction<SidebarData>>,
  previousLayer: React.MutableRefObject<string>,
  coords: CoordsProps,
  selectedCorpIds: SelectedCorpIds,
  setSelectedCorpIds: React.Dispatch<React.SetStateAction<SelectedCorpIds>>,
}) {

  const mapContainer = useRef<HTMLDivElement>(null);
  const {long, lat, zoom, setCoords} = coords;

  // Initialize map
  useEffect(() => {
    if (map.current) return; // initialize map only once

    try {
      console.log("Initializing map", {lat, long, zoom})
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [long, lat],
        zoom: zoom,
      });
    } catch (e) {
      console.error('Error initializing map', e);
    }
  });


  useEffect(() => {

    setSidebarData({
      addresses: selectedCorpIds.map(id => ({source: 'details', CorpId: id.toString()})),
    });

    if (selectedCorpIds.length === 0) return;

    setSidebarState('open');
  }, [selectedCorpIds, setSidebarData, setSidebarState]);

  useEffect(() => {
    if (!map.current) return;

    const mapMoveEffect = mapOnMoveClosure({
      setCoords,
      map,
    });

    map.current.on('moveend', mapMoveEffect);

    return () => {
      map.current.off('moveend', mapMoveEffect);
    };

  }, [map, setCoords]);


  useEffect(() => {
    if (!map.current) return;

    // if params are null, we load all parcels/addresses in the current viewport, otherwise we load based on params and make all parcels invisible
    const mapLoadEvent = () => {
      // we want to load for the viewport
      
      loadData({map, selectedCorpIds});
      previousLayer.current = 'all-parcels';

      arrangeLayers({map});

      map.current.on('moveend', () => {
        loadData({map, selectedCorpIds});
      });
    };
    
    map.current.on('load', mapLoadEvent);

    return () => {
      map.current.off('load', mapLoadEvent);
    };

  }, [map, previousLayer, selectedCorpIds]);

  useEffect(() => {
    if (!map.current) return;

    // When a click event occurs on a feature, fill the sidebar with corresponding information
    const mapClickEvent = mapOnClickClosure({
      map,
      setSelectedCorpIds,
    });

    map.current.on('click', mapClickEvent);

    return () => {
      map.current.off('click', mapClickEvent);
    };

  }, [map, setSelectedCorpIds]);

  useEffect(() => {
    if (!map.current) return;

    const mouseMoveEvent = function () {
      map.current.getCanvas().style.cursor = 'pointer';
    };

    // Change the cursor to a pointer when the mouse is over the parcels layer
    map.current.on('mousemove', 'all-parcels-shading', mouseMoveEvent);
    map.current.on('mousemove', 'linked-parcels-shading', mouseMoveEvent);
    map.current.on('mousemove', 'selected-parcels-shading',mouseMoveEvent);


    const mouseLeaveEvent = function () {
      map.current.getCanvas().style.cursor = '';
    };

    // Change it back to a pointer when it leaves
    map.current.on('mouseleave', 'all-parcels-shading', mouseLeaveEvent);
    map.current.on('mouseleave', 'linked-parcels-shading', mouseLeaveEvent);
    map.current.on('mouseleave', 'selected-parcels-shading', mouseLeaveEvent);

    return () => {
      // remove all event handlers from map on useEffect teardown
      map.current.off('mousemove', 'all-parcels-shading', mouseMoveEvent);
      map.current.off('mousemove', 'linked-parcels-shading', mouseMoveEvent);
      map.current.off('mousemove', 'selected-parcels-shading',mouseMoveEvent);
      
      map.current.off('mouseleave', 'all-parcels-shading', mouseLeaveEvent);
      map.current.off('mouseleave', 'linked-parcels-shading', mouseLeaveEvent);
      map.current.off('mouseleave', 'selected-parcels-shading', mouseLeaveEvent);
    }
  }, [map, setSidebarData, previousLayer, setCoords]);


  return (
    <div ref={mapContainer} className="map-container" />
  );
}

// load data from viewport
function loadData({
  map,
  visibility = 'visible',
  selectedCorpIds,
}: {
  map: React.MutableRefObject<any>,
  visibility?: LayoutVisibilityProperty,
  selectedCorpIds: SelectedCorpIds,
}) {
  let bounds = map.current.getBounds();  // gets location data of SW and NE corners of map

  ApiService.getAddressesFromBounds(bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat).then(function (addresses) {
    let sourceName: SourceName = 'all-addresses';
    let circleLayerId: CircleLayerId = 'all-address-points';
    loadAddresses({map, addresses, sourceName, circleLayerId, visibility, selectedCorpIds});
  });
}


function loadAddresses({
  map, 
  addresses, 
  sourceName, 
  circleLayerId, 
  visibility = 'visible',
  selectedCorpIds,
}:{
  map: React.MutableRefObject<any>,
  addresses: unknown,
  sourceName: SourceName,
  circleLayerId: CircleLayerId,
  visibility?: LayoutVisibilityProperty,
  selectedCorpIds?: SelectedCorpIds,
}) {
  if (map.current.getSource(sourceName)) {
    map.current.getSource(sourceName).setData(addresses);
  }
  else {
    // adds a single data source of geojson data to map containing all addresses returned from the api query
    map.current.addSource(sourceName, {
      "type": "geojson",
      "data": addresses
    });

    // Active layers get active colors
    let circleColor = '#c90076';

    // The base layer gets a basic color
    if (circleLayerId === 'all-address-points') {
      circleColor = '#000';
    }

    // adds a layer to visualize the addresses
    map.current.addLayer({
      'id': circleLayerId,
      'type': 'circle',
      'source': sourceName,
      'layout': {
        'visibility': visibility
      },
      'paint': {
        'circle-radius': {
          'base': 1.75,
          'stops': [
              [12, 2],
              [22, 60]
          ]
      },
        'circle-color': circleColor
      }
    });

    selectedCorpIds = selectedCorpIds.length ? selectedCorpIds : [-1];

    map.current.addLayer({
      'id': 'corporations-highlighted',
      'type': 'circle',
      'source': sourceName,
      'layout': {
        'visibility': 'visible'
      },
      'paint': {
        'circle-radius': {
          'base': 1.75,
          'stops': [
              [12, 2],
              [22, 60]
          ]
      },
        'circle-color': '#D9027D'
      },
      'filter': ['match', ['get', 'CorpId'], selectedCorpIds, true, false]
    });

    map.current.moveLayer(circleLayerId, 'corporations-highlighted');

  }
}

function arrangeLayers({ 
  map, 
  parcelLayerToWaitFor = 'all-parcels-shading', 
  addressLayerToWaitFor = 'all-address-points'
}: {
  map: React.MutableRefObject<any>,
  parcelLayerToWaitFor?: ShadingLayerId,
  addressLayerToWaitFor?: CircleLayerId,
}) {
  // wait for all-parcels-shading to load - this source takes the longest - then adjust the other layers
  if (!map.current.getLayer(parcelLayerToWaitFor) && (!map.current.getLayer(addressLayerToWaitFor))) {
    setTimeout(() => {
      
      arrangeLayers({
        map,
        parcelLayerToWaitFor,
        addressLayerToWaitFor,
      });
    }, 200);
  } else {
    // TODO: we really need a better way of ordering layers. Where we can specify a z-index
    // for each layer and then call a method that will order them. Then we can expose an interface
    // to allow users to order their layers as they see fit (but we should still think about how
    // highlight and linked layers work. They need to be above the base layer).
    map.current.moveLayer('all-address-points');
    map.current.moveLayer('corporations-highlighted');
  }
};


function mapOnMoveClosure({
  setCoords,
  map,
}:{
  setCoords: SetCoordsAction,
  map: React.MutableRefObject<any>,
}) {
  
  return function mapOnMove() {
    const coords = {
      long: map.current.getCenter().lng.toFixed(7) as number,
      lat: map.current.getCenter().lat.toFixed(7) as number,
      zoom: map.current.getZoom().toFixed(2) as number,
    };
    setCoords(coords);
  }
}


function mapOnClickClosure({
  map,
  setSelectedCorpIds,
}: {
  map: React.MutableRefObject<any>,
  setSelectedCorpIds: React.Dispatch<React.SetStateAction<SelectedCorpIds>>,
}) {
  
  return function mapOnClick (e) {
    var features = map.current.queryRenderedFeatures(e.point);

    var corpIds = [];
    var justCorpIds = [];

    features.forEach(feature => {
      if (
        feature.layer.id === 'all-address-points' || 
        feature.layer.id === 'linked-address-points' || 
        feature.layer.id === 'selected-address-points'
      ) {
        corpIds.push(feature.properties);
        justCorpIds.push(feature.properties.CorpId);
      }
    });

    setSelectedCorpIds(justCorpIds);

    justCorpIds = justCorpIds.length ? justCorpIds : [-1];

    map.current.setFilter('corporations-highlighted', ['match', ['get', 'CorpId'], justCorpIds, true, false]);

  }
}